export default [
  {
    step: '01',
    title: '인터뷰',
    subTitle: 'Interview',
    description:
      '기획서가 있다면 기획서를 토대로 프로그램이 어떤방식으로 개발될지, 무엇이 필요한지에 대해 이야기합니다.<br><br>기획서가 없을 경우 아이디어를 파악하는 단계입니다. 대부분의 경우 대화로는 커뮤니케이션이 어렵기 때문에, 직접그린 손그림이나 참고할만한 다른 사람의 프로젝트를 참고하게 됩니다.',
  },
  {
    step: '02',
    title: '디자인',
    subTitle: 'Design',
    description:
      '기획된 컨셉에 따라 뼈대모양만 갖춘 상태인 일종의 설계도를 공유합니다.<br>프로그램의 생김새나, 로직에 대해 대략적으로 파악하게 됩니다. 개발이 시작된 이후 흐른 시간만큼 로직 또는 메인 디자인의 변경 난이도가 점점 증가하기 때문에 이 단계에서 프로그램의 구상이 명확해지는 만큼 작업효율이 증가합니다.',
  },
  {
    step: '03',
    title: '개발',
    subTitle: 'Develop',
    description:
      '프로그램의 개발이 시작되면 프로그램의 성격에 따라 공개해도 되는 경우 작업 진행도를 직접 확인해 볼 수 있는 URL을 제공해드립니다.<br><br>프로그램 개발 진행중에도 수정, 보완이 가능합니다.',
  },
  {
    step: '04',
    title: '배포',
    subTitle: 'deploy',
    description:
      '개발이 완성되고 기능을 하나씩 확인해본 후에 이상이 없는 경우 코드 또는 프로그램 파일을 제공하거나 서버에 배포합니다.',
  },
]
