<template>
  <div class="px-4 lg:px-main text-theme7">
    <div class="h-40 sm:h-48 lg:h-52"></div>
    <div class="move-down relative -top-6 flex gap-4 justify-center items-center pb-12 lg:pb-24">
      <div class="border w-4 lg:w-12"></div>
      <div class="text-center font-barlow text-lg sm:text-2xl lg:text-4xl font-semibold">About Me.</div>
      <div class="border w-4 lg:w-12"></div>
    </div>
    <div class="grid grid-cols-1 sm:grid-cols-12">
      <!-- left photo -->
      <div class="move-right relative -left-6 sm:col-span-5 flex items-center justify-center pb-6 lg:pb-0">
        <div>
          <div>
            <img src="/images/about-photo.png" alt="about-photo" />
          </div>
          <div class="text-center font-barlow text-lg lg:text-4xl pt-5 font-semibold">The Breeze</div>
          <div class="text-center font-barlow text-sm lg:text-2xl font-light">thebreeze@gmail.com</div>
        </div>
      </div>
      <!-- mobile devide line -->
      <div class="block sm:hidden border border-theme4 opacity-50"></div>
      <!-- center line -->
      <div class="hidden sm:block border-l border-theme2 ml-8"></div>
      <!-- right about me -->
      <div class="move-left relative left-6 sm:col-span-6 pb-6 lg:pb-12">
        <div class="text-center font-barlow text-xl lg:text-4xl pt-5 font-semibold">Yeah, I'm Breeze.</div>
        <div class="font-gothic leading-5 lg:leading-9 text-sm md:text-lg lg:text-xl font-light pt-4 lg:pt-8">
          안녕하세요? 브리즈입니다.<br />
          개발과 디자인을 함께하고 있어요.<br /><br />
          저는 원래 취미로 개발하는 직장인이었어요. 본업은 개발과는 상관없는 일을 하고 있었습니다. 그러다가 어느날
          코딩이 배워보고 싶어진 저는 웹사이트를 만들고 운영하고, 주변사람들이 필요한 프로그램을 하나, 둘 만들어
          주다보니 여기까지 오게 되었네요.<br /><br />
          만드는 과정이 너무 재밌더라구요.<br />
          그렇게 저는 <b>여기까지</b> 오게되었습니다.
        </div>
      </div>
      <!-- mobile devide line -->
      <div class="block sm:hidden border border-theme4 opacity-50"></div>
      <!-- middle line -->
      <div class="hidden sm:block sm:col-span-12 border-b border-theme2"></div>
      <!-- Abilites -->
      <div class="move-right relative -left-6 sm:col-span-5 pb-6 lg:pb-12">
        <div class="text-center font-barlow text-xl lg:text-4xl pt-6 font-semibold">Abilites</div>
        <div class="font-barlow text-xl lg:text-3xl pt-4">Design</div>
        <div class="font-barlow font-light text-lg lg:text-2xl pt-4 flex items-center justify-between">
          <div>&nbsp;&nbsp;•&nbsp;&nbsp;Design</div>
          <div class="flex"><StarScore :stars="[3, 1, 1]" /></div>
        </div>
        <div class="font-barlow font-light text-lg lg:text-2xl pt-4 flex justify-between">
          <div>&nbsp;&nbsp;•&nbsp;&nbsp;Tools</div>
          <div class="flex"><StarScore :stars="[3, 0, 2]" /></div>
        </div>
        <div class="font-barlow font-light text-lg lg:text-2xl pt-4 flex justify-between">
          <div>&nbsp;&nbsp;•&nbsp;&nbsp;UI/UX</div>
          <div class="flex"><StarScore :stars="[4, 0, 1]" /></div>
        </div>
        <div class="font-barlow text-xl lg:text-3xl pt-4">Develop</div>
        <div class="font-barlow font-light text-lg lg:text-2xl pt-4 flex items-center justify-between">
          <div>&nbsp;&nbsp;•&nbsp;&nbsp;Frontend</div>
          <div class="flex"><StarScore :stars="[5, 0, 0]" /></div>
        </div>
        <div class="font-barlow font-light text-lg lg:text-2xl pt-4 flex justify-between">
          <div>&nbsp;&nbsp;•&nbsp;&nbsp;Backend</div>
          <div class="flex"><StarScore :stars="[4, 0, 1]" /></div>
        </div>
        <div class="font-barlow font-light text-lg lg:text-2xl pt-4 flex justify-between">
          <div>&nbsp;&nbsp;•&nbsp;&nbsp;Management</div>
          <div class="flex"><StarScore :stars="[4, 0, 1]" /></div>
        </div>
        <div class="font-barlow text-xl lg:text-3xl pt-4">Others</div>
        <div class="font-barlow font-light text-lg lg:text-2xl pt-4 flex items-center justify-between">
          <div>&nbsp;&nbsp;•&nbsp;&nbsp;Communication</div>
          <div class="flex"><StarScore :stars="[4, 0, 1]" /></div>
        </div>
      </div>
      <!-- mobile devide line -->
      <div class="block sm:hidden border border-theme4 opacity-50"></div>
      <!-- center line -->
      <div class="hidden sm:block border-l border-theme2 ml-8"></div>
      <!-- Skills -->
      <div class="move-left relative left-6 sm:col-span-6 pb-6 lg:pb-12">
        <div class="text-center font-barlow text-xl lg:text-4xl pt-6 font-semibold">Skills</div>
        <div class="font-barlow text-xl lg:text-3xl pt-4">Languages</div>
        <div class="font-barlow font-light text-lg lg:text-2xl pt-4">
          <div class="">&nbsp;&nbsp;•&nbsp;&nbsp;Javascript, PHP, Dart</div>
        </div>
        <div class="font-barlow text-xl lg:text-3xl pt-4">Frameworks & Libraries</div>
        <div class="font-barlow font-light text-lg lg:text-2xl pt-4">
          <div class="">&nbsp;&nbsp;•&nbsp;&nbsp;NodeJS, Vuejs, Electron</div>
          <div class="pt-2">&nbsp;&nbsp;•&nbsp;&nbsp;Laravel, Flutter</div>
          <div class="pt-2">&nbsp;&nbsp;•&nbsp;&nbsp;Tailwindcss</div>
        </div>
        <div class="font-barlow text-xl lg:text-3xl pt-4">Others</div>
        <div class="font-barlow font-light text-lg lg:text-2xl pt-4">
          <div class="">&nbsp;&nbsp;•&nbsp;&nbsp;AWS, Mysql, Docker etc...</div>
        </div>
      </div>
    </div>
  </div>
  <div class="relative mt-12 lg:mt-32">
    <div
      class="
        absolute
        h-full
        w-full
        px-4
        lg:px-main
        flex
        items-center
        justify-center
        text-theme9
        font-gothic font-semibold
        text-lg
        md:text-2xl
        lg:text-4xl
      "
    >
      <div class="block">
        단군 이래 가장&nbsp;<span class="text-theme10 font-bold text-xl md:text-2xl lg:text-5xl">코</span>딩하기 좋은
        시대
        <div class="font-barlow text-sm lg:text-2xl font-thin w-full text-right">- I think so</div>
      </div>
    </div>

    <img class="w-full" src="/images/about-image.png" alt="about-image" />
  </div>
</template>

<script>
import StarScore from '@/components/StarScore'
import { onMounted } from '@vue/runtime-core'
import { intersectionObserver } from '@/utils/intersectionObserver'

export default {
  name: 'About',

  components: {
    StarScore,
  },

  setup() {
    onMounted(() => {
      const moveDown = 'transition ease-out duration-1000 transform translate-y-6'
      intersectionObserver('move-down', moveDown)
      const moveLeft = 'transition ease-out duration-1000 transform -translate-x-6'
      intersectionObserver('move-left', moveLeft)
      const moveRight = 'transition ease-out duration-1000 transform translate-x-6'
      intersectionObserver('move-right', moveRight)
    })
  },
}
</script>
