<template>
  <div class="grid grid-cols-1 lg:grid-cols-2 gap-8">
    <div class="mt-8 flex justify-center">
      <img class="shadow-lg" :src="`/images/work${step}.png`" :alt="`work-${step}`" />
    </div>
    <div class="-mt-20 sm:-mt-28 lg:mt-8">
      <div
        class="
          font-gothic font-semibold
          text-4xl
          sm:text-6xl
          lg:text-3xl
          text-center
          pb-8
          lg:pb-0
          text-theme4
          lg:text-theme7
        "
      >
        {{ title }}
      </div>
      <div class="hidden lg:block font-barlow font-light text-xl text-center">{{ subTitle }}</div>
      <div class="hidden lg:block border-t my-4"></div>
      <div class="flex justify-center">
        <div class="font-gothic max-w-xl" v-html="description"></div>
      </div>
    </div>
    <div class="block lg:hidden border"></div>
  </div>
</template>

<script>
export default {
  props: {
    step: String,
    title: String,
    subTitle: String,
    description: String,
  },
}
</script>
