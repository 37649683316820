<template>
  <div class="px-4 lg:px-main text-theme7">
    <div class="h-40 sm:h-48 lg:h-52"></div>
    <div class="move-down relative -top-6 flex gap-4 justify-center items-center pb-12 lg:pb-24">
      <div class="border w-4 lg:w-12"></div>
      <div class="text-center font-barlow text-lg sm:text-2xl lg:text-4xl font-semibold">Work Process</div>
      <div class="border w-4 lg:w-12"></div>
    </div>
    <WorkCard
      v-for="step in steps"
      class="scale-up transform scale-75"
      :key="`work-step-${step.step}`"
      :step="step.step"
      :title="step.title"
      :subTitle="step.subTitle"
      :description="step.description"
    />
  </div>
  <div class="relative mt-12 lg:mt-32">
    <div
      class="
        absolute
        h-full
        w-full
        px-4
        sm:px-main
        flex
        items-center
        justify-center
        text-theme9
        font-gothic font-semibold
        text-lg
        sm:text-2xl
        lg:text-4xl
      "
    >
      <div class="block">
        Design <span class="font-light">is directed toward human beings</span>
        <div class="font-barlow font-light text-sm sm:text-xl lg:text-2xl w-full text-right">- Ivan Chermayeff</div>
      </div>
    </div>

    <img class="w-full" src="/images/work-image.png" alt="about-image" />
  </div>
</template>

<script>
import WorkCard from '@/components/WorkCard'
import workSteps from '@/utils/workSteps'
import { reactive } from '@vue/reactivity'
import { onMounted } from '@vue/runtime-core'
import { intersectionObserver } from '@/utils/intersectionObserver'

export default {
  name: 'About',

  components: {
    WorkCard,
  },

  setup() {
    const steps = reactive(workSteps)

    onMounted(() => {
      const moveDown = 'transition ease-out duration-1000 transform translate-y-6'
      intersectionObserver('move-down', moveDown)
      const scaleUp = 'transition ease-out duration-300 transform scale-100'
      intersectionObserver('scale-up', scaleUp)
    })

    return { steps }
  },
}
</script>
