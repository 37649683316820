<template>
  <div class="px-4 lg:px-main text-theme7">
    <div class="h-40 sm:h-48 lg:h-52"></div>
    <div class="move-down relative -top-6 flex gap-4 justify-center items-center pb-1 lg:pb-4">
      <div class="border w-4 lg:w-12"></div>
      <div class="text-center font-barlow text-xl sm:text-2xl lg:text-4xl font-semibold">Contact Me!</div>
      <div class="border w-4 lg:w-12"></div>
    </div>
    <div class="move-top relative top-6 pb-4 lg:pb-8">
      <div class="text-center font-barlow text-lg sm:text-xl lg:text-3xl font-medium">Email</div>
      <div class="text-center font-barlow text-md sm:text-lg lg:text-2xl font-light">brez.dev@gmail.com</div>
    </div>
    <div class="move-top relative top-6 pb-32 lg:pb-44">
      <div class="text-center font-barlow text-lg sm:text-xl lg:text-3xl font-medium">Kakao Open Chat</div>
      <div class="flex justify-center font-barlow text-md sm:text-lg lg:text-2xl font-light">
        <a class="flex items-center" href="https://open.kakao.com/o/glPHglBd" target="_blank">
          https://open.kakao.com/o/glPHglBd
          <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            data-icon="external-link-alt"
            class="mt-0.5 ml-1 h-4 w-4 svg-inline--fa fa-external-link-alt fa-w-16"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
          >
            <path
              fill="currentColor"
              d="M432,320H400a16,16,0,0,0-16,16V448H64V128H208a16,16,0,0,0,16-16V80a16,16,0,0,0-16-16H48A48,48,0,0,0,0,112V464a48,48,0,0,0,48,48H400a48,48,0,0,0,48-48V336A16,16,0,0,0,432,320ZM488,0h-128c-21.37,0-32.05,25.91-17,41l35.73,35.73L135,320.37a24,24,0,0,0,0,34L157.67,377a24,24,0,0,0,34,0L435.28,133.32,471,169c15,15,41,4.5,41-17V24A24,24,0,0,0,488,0Z"
            ></path>
          </svg>
        </a>
      </div>
    </div>
    <div class="move-down relative -top-6 flex gap-4 justify-center items-center pb-1 lg:pb-4">
      <div class="border w-4 lg:w-12"></div>
      <div class="text-center font-barlow text-xl sm:text-2xl lg:text-4xl font-semibold">Others</div>
      <div class="border w-4 lg:w-12"></div>
    </div>
    <div class="move-top relative top-6 pb-12 lg:pb-36">
      <div class="text-center font-barlow text-lg sm:text-xl lg:text-3xl font-medium">Blog</div>
      <div class="flex justify-center font-barlow text-md sm:text-lg lg:text-2xl font-light">
        <a class="flex items-center" href="https://arikong.tistory.com" target="_blank">
          https://arikong.tistory.com
          <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            data-icon="external-link-alt"
            class="mt-0.5 ml-1 h-4 w-4 svg-inline--fa fa-external-link-alt fa-w-16"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
          >
            <path
              fill="currentColor"
              d="M432,320H400a16,16,0,0,0-16,16V448H64V128H208a16,16,0,0,0,16-16V80a16,16,0,0,0-16-16H48A48,48,0,0,0,0,112V464a48,48,0,0,0,48,48H400a48,48,0,0,0,48-48V336A16,16,0,0,0,432,320ZM488,0h-128c-21.37,0-32.05,25.91-17,41l35.73,35.73L135,320.37a24,24,0,0,0,0,34L157.67,377a24,24,0,0,0,34,0L435.28,133.32,471,169c15,15,41,4.5,41-17V24A24,24,0,0,0,488,0Z"
            ></path>
          </svg>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted } from '@vue/runtime-core'
import { intersectionObserver } from '@/utils/intersectionObserver'

export default {
  name: 'Contact',

  components: {},

  setup() {
    onMounted(() => {
      const moveDown = 'transition ease-out duration-1000 transform translate-y-6'
      intersectionObserver('move-down', moveDown)
      const moveTop = 'transition ease-out duration-1000 transform -translate-y-6'
      intersectionObserver('move-top', moveTop)
    })

    return {}
  },
}
</script>
